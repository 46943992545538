// src/plugins/toast.js
export default {
    install(Vue) {
        Vue.prototype.$showToast = function (type, message, options = {}) {
            let config = {
                position: 'top-right',
                timeout: 3000,
                closeOnClick: true,
                pauseOnFocusLoss: true,
                pauseOnHover: false,
                draggable: true,
                draggablePercent: 0.4,
                showCloseButtonOnHover: false,
                hideProgressBar: true,
                closeButton: 'button',
                icon: false,
                rtl: false,
                ...options, // Позволяет переопределить стандартные настройки
            }

            if (type === 'success') {
                this.$toast.success(message, config)
            } else if (type === 'error') {
                this.$toast.error(message, config)
            } else if (type === 'info') {
                this.$toast.info(message, config)
            } else if (type === 'warning') {
                this.$toast.warning(message, config)
            } else {
                console.warn(`Unknown toast type: ${type}`)
            }
        }
    },
}
